import { useClientConfig } from '/machinery/ClientConfig'
import { usePlayWhenVisible } from '/machinery/usePlayWhenVisible'

import poster from '/images/video-loop-poster.png'

import styles from './ShadowCasting.css'

export function ShadowCasting({ styleContext = undefined, layoutClassName = undefined }) {
  const [suspended, setSuspended] = React.useState(false)
  const videoRef = React.useRef(null)
  const config = useClientConfig()

  const { ref: visibilityRef } = usePlayWhenVisible({
    onNotAllowed: setSuspended,
    elementRef: videoRef,
  })

  const src = config.videos.shadowCasting

  return (
    <div
      ref={visibilityRef}
      className={cx(styles.component, suspended && styles.isSuspended, layoutClassName)}
      data-style-context={styleContext}
    >
      <Video ref={videoRef} {...{ src, suspended, poster }} />
      <img className={styles.fallbackElement} role='presentation' alt='' src={poster} />
    </div>
  )
}

const Video = React.forwardRef(VideoImpl)
function VideoImpl({ src, suspended, poster }, ref) {
  return (
    <video
      loop
      muted
      autoPlay
      playsInline
      width="1080"
      height="1080"
      controls={false}
      aria-hidden="true"
      role="presentation"
      className={cx(
        styles.componentVideoImpl,
        suspended && styles.isSuspended
      )}
      {...{ ref, src, poster }}
    />
  )
}
